import styled from 'styled-components';
import { MEDIA_QUERY } from '../../constants/style';

export const LandingWrepper = styled.div`
  margin: auto;
  width: 100%;
  @media ${MEDIA_QUERY.laptop} { 
    height: auto;
  }
`
