import { useState } from "react"
import styled from "styled-components"
import SvgTriangle from "../icons/Triangle"

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const IconWrapper = styled.div`
  margin-left: 10px;
  transform: ${props => props.show ? 'rotate(180deg)' : 'none'};
`

const ChildrenWrapper = styled.div`
  padding-left: 20px;
  padding-top: 20px;
`

const Expandable = ({ children, label }) => {
  const [show, setShow] = useState(false)
  return (
    <div>
      <LabelWrapper onClick={() => setShow(!show)}>
        {label}
        <IconWrapper show={show}><SvgTriangle /></IconWrapper>
      </LabelWrapper>
      {
        show && <ChildrenWrapper>{children}</ChildrenWrapper>
      }
    </div>
  )
}

export default Expandable;
