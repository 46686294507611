// Use https://github.com/eivindfjeldstad/typecast

/**
 * Cast given `val` to `type`
 *
 * @param {Mixed} val
 * @param {String} type
 * @api public
 */

export default function typecast(val, type) {
  const fn = typecast[type];
  if (typeof fn != 'function') throw new Error('cannot cast to ' + type);
  return fn(val);
}

/**
 * Cast `val` to `String`
 *
 * @param {Mixed} val
 * @api public
 */

typecast.string = function (val) {
  if (null == val) return '';
  return val.toString();
};

/**
 * Cast `val` to `Number`
 *
 * @param {Mixed} val
 * @api public
 */

typecast.number = function (val) {
  const num = parseFloat(val);
  return isNaN(num)
    ? 0
    : num;
};

/**
 * Cast `val` to a`Date`
 *
 * @param {Mixed} val
 * @api public
 */

typecast.date = function (val) {
  const date = new Date(val);
  return isNaN(date.valueOf())
    ? new Date(0)
    : date;
};

/**
 * Cast `val` to `Array`
 *
 * @param {Mixed} val
 * @api public
 */

typecast.array = function (val) {
  if (val == null) return [];
  if (val instanceof Array) return val;
  if (typeof val != 'string') return [val];

  const arr = val.split(',');
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].trim();
  }

  return arr;
};

/**
 * Cast `val` to `Boolean`
 *
 * @param {Mixed} val
 * @api public */

typecast.boolean = function (val) {
  return !! val && val !== 'false' && val !== '0';
};
