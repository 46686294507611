import { HeaderRootWrapper, HeaderWrapper } from '../style/HeaderWrapper';
import Navigation from '../nav/Navigation';
import styled from 'styled-components';
import { MEDIA_QUERY } from '../../constants/style';
import Link from 'next/link';
import SvgPurrmasterLogo from '../icons/PurrmasterLogo';
import { useState } from 'react';
import SvgHamburger from '../icons/Hamburger';
import MenuDrawer from './MenuDrawer';

const TitleWrapper = styled.h1`
  font-size: 23px;
  color: #000000;
  margin: auto;

  @media ${MEDIA_QUERY.mobileL} { 
    margin: 0;
  }
`

const TitleLinkWrapper = styled.a`
  cursor: pointer;
  text-align: center;
  @media ${MEDIA_QUERY.mobileL} { 
    text-align: initial;
  }
`

const HeaderRowWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media ${MEDIA_QUERY.mobileL} { 
    display: block;
    justify-content: initial;
  }
`

const HamburgerWrapper = styled.span`
  position: absolute;
  left: 10px;
  top: 30px;
  padding-right: 15px;
  @media ${MEDIA_QUERY.tablet} { 
    display: none;
  }
`

export default function Header(props) {
  const { i18n, liveConfig } = props
  const [ showMenu, setShowMenu ] = useState(false)
  return (
    <HeaderRootWrapper>
      <HeaderWrapper>
      
        <HeaderRowWrapper>
          <HamburgerWrapper onClick={() => setShowMenu(true)} >
            <SvgHamburger/>    
          </HamburgerWrapper>
          <Link passHref href='/'>
            <TitleLinkWrapper>
              <TitleWrapper><SvgPurrmasterLogo/></TitleWrapper>
            </TitleLinkWrapper>
          </Link>
        </HeaderRowWrapper>
        <Navigation i18n={i18n} liveConfig={liveConfig} />
      </HeaderWrapper>
      <MenuDrawer i18n={i18n} setShowMenu={setShowMenu} showMenu={showMenu} />
    </HeaderRootWrapper>
  )
}
