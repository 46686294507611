import { user } from "./clientStorage"

export default function getUserPicture(picture) {
  if (!picture) { return picture }
  const data = user.get()
  if (data.fbToken) {
    return `${picture}?access_token=${data.fbToken}`
  }
  return picture
}
