import { useEffect, useState } from "react";
import styled from "styled-components";
import SvgPuffLoading from "../icons/Puff";
import DelayedUiWrapper from '../style/DelayedUiWrapper';

export const LOADING_MODE = {
  OVERLAY: 'overlay',
  TRAILING: 'trailing'
}

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  /* display: ${(props) => props.isInline ? 'inline-block' : 'block'} */
  /* display: inline; */
`

const SVGWrapper = styled.div`
  position: absolute;
  align-items: center;
  top: 40%;
  left: 40%;
`

export const ClickLoading = ({
  children, Placeholder = SvgPuffLoading, isInline = false,
  mode = LOADING_MODE.OVERLAY, stopLoading = false
}) => {
  const [ isLoading, setIsLoading ] = useState(false);
  useEffect(() => {
    if (stopLoading) {
      setIsLoading(false)
    }
  }, [stopLoading])
  return (
    <LoadingWrapper
      isInline={isInline}
      onClick={() => setIsLoading(true)}
    >
      {
        (isLoading && mode === LOADING_MODE.OVERLAY) &&
        <DelayedUiWrapper delay={0.3}>
          <SVGWrapper>
            <Placeholder />
          </SVGWrapper>
        </DelayedUiWrapper>
      }
      {children}
      {
        (isLoading && mode === LOADING_MODE.TRAILING) &&
          <DelayedUiWrapper delay={0.3} inline={true}><Placeholder/></DelayedUiWrapper>
      }
    </LoadingWrapper>
  )
}
