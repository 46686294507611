import React from 'react';
import styled from 'styled-components';

const CrossWrapper = styled.div`
  color: ${(props) => props.color || '#000'};
`;

const SVGWrapper = styled.svg`
  fill: currentColor;
  cursor: pointer;
`;

export default function Cross(props) {
  return (
    <CrossWrapper color={props.color}>
      <SVGWrapper
        height={ props.height || '1.5em'}
        onClick={props.onClick}
        viewBox="0 0 24 24"
        width={ props.width || '1.5em'}
      >
        <g>
          <path d="M13.414 12l5.793-5.793c.39-.39.39-1.023 0-1.414s-1.023-.39-1.414 0L12 10.586 6.207 4.793c-.39-.39-1.023-.39-1.414 0s-.39 1.023 0 1.414L10.586 12l-5.793 5.793c-.39.39-.39 1.023 0 1.414.195.195.45.293.707.293s.512-.098.707-.293L12 13.414l5.793 5.793c.195.195.45.293.707.293s.512-.098.707-.293c.39-.39.39-1.023 0-1.414L13.414 12z">
          </path>
        </g>
      </SVGWrapper>
    </CrossWrapper>
  );
}
