import React from "react";

function SvgUser(props) {
  return (
    <svg height={25} width={25} {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        style={{
          mixBlendMode: "multiply"
        }}
        transform="translate(2.75 2.854)"
      >
        <path d="M9.6 11.333c-3.2 0-5.6 2.534-5.6 5.6C5.6 18 7.6 18.8 9.6 18.8c2 0 4-.667 5.6-1.867 0-3.066-2.533-5.6-5.6-5.6z" />
        <circle cx={9.6} cy={9.6} fill="#FFF" fillRule="nonzero" r={3.867} />
        <circle cx={9.6} cy={9.467} r={9.467} />
      </g>
    </svg>
  );
}

export default SvgUser;
