import React from "react";

function SvgTriangle(props) {
  return (
    <svg height={8} width={10} {...props}>
      <path d="M5 8.5l5-8H0z" fillRule="evenodd"/>
    </svg>
  );
}

export default SvgTriangle;
