import React from "react";

function SvgHamburger(props = {}) {
  return (
    <svg height="20" viewBox="0 0 100 80" width="20">
        <rect height="15" rx="8" width="100"></rect>
        <rect height="15" rx="8" width="100" y="30"></rect>
        <rect height="15" rx="8" width="100" y="60"></rect>
    </svg>
  )
}

export default SvgHamburger
