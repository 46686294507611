import styled from "styled-components";
import { DOT_COLOR } from "../../constants/style";

const Dot = styled.span`
  height: ${(props) => props.height ? props.height : '10px'};
  width: ${(props) => props.width ? props.width : '10px'};
  background-color: ${(props) => props.bgColor ? props.bgColor : DOT_COLOR};
  border-radius: 6.3px;
  display: inline-block;
`

export default Dot;
