import Slider from "react-slick";
import styled from "styled-components"
import { MEDIA_QUERY, TOUCH_THRESDHOLD } from '../../constants/style'
import Dot from "../style/Dot";
import NextButtonWrapper from "./style/NextButtonWrapper";
import PrevButtonWrapper from "./style/PrevButtonWrapper";
import CoverLink from "./features/CoverLink";
import ConfigV1 from '../../live-config/config-v1.json';
import CoverContent from "./features/CoverContent";

const AUTOPLAY_SPEED = 5000

const DotWraper = styled.div`
  position: absolute;
  bottom: 10px;
  @media ${MEDIA_QUERY.mobileL} {
    display: none;
  }
`

const UlWrapper = styled.ul`
  padding: 0;
`

function NextArrow(props) {
  const { onClick } = props;
  return (
    <NextButtonWrapper
      onClick={onClick}
    >

    </NextButtonWrapper>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <PrevButtonWrapper
      onClick={onClick}
    >
    </PrevButtonWrapper>
  );
}

export const CoverCarousel = ({ liveConfig }) => {
  const config = liveConfig ? liveConfig : ConfigV1
  const coverImages = config.topNotch
  const settings = {
    appendDots: dots => (
      <DotWraper className='carousel-dot-wrapper'>
        <UlWrapper>{dots}</UlWrapper>
      </DotWraper>
    ),
    autoplay: true,
    autoplaySpeed: AUTOPLAY_SPEED,
    customPaging: () => <Dot bgColor='#a8a8a8' />,
    dots: true,
    infinite: true,
    lazyLoad: 'anticipated',
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    touchThreshold: TOUCH_THRESDHOLD,
  };

  return (
    <Slider {...settings}>
      {
        coverImages.map((cover, index) => {
          return (
            cover.link ? 
            <CoverLink
              key={index}
              cover={cover}
              isInternal={cover.isInternal}
            >
              <CoverContent
                cover={cover}
              />
            </CoverLink> :
            <CoverContent
              key={index}
              cover={cover}
            />
          )
        })
      }
    </Slider>
  )
}