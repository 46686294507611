import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import styled, { keyframes } from 'styled-components';
import { BLOG_CONTROVERSIAL_GUM_PATHNAME, BLOG_NUTRITION_PATHNAME, BLOG_PLANT_PATHNAME, CALCULATOR_PATHNAME, CAT_INSURANCE, COMPLETE_AND_SUPPLEMENT_PATHNAME, COMPLETE_DRY_CATEGORY, COMPLETE_WET_CATEGORY, FOOD_ANAYLYSIS, SEARCH_PRODUCT_PATHNAME, TOP_10_PRODUCTS, USER_FAV_PATHNAME } from '../../constants/route';
import { BUTTON_LOADING_SMALL, MEDIA_QUERY, MENU_ZINDEX, NAVIGATION_HIGHLIGHT_COLOR } from '../../constants/style';
import { ClickLoading, LOADING_MODE } from '../common/ClickLoading';
import Cross from '../icons/Cross';
import SvgFavorite from '../icons/Favorite';
import SvgPurrmasterLogo from '../icons/PurrmasterLogo';
import SvgThreeDots from '../icons/ThreeDots';
import NavUserBadge from '../user/NavBadge';
import Expandable from './Expandable';


const MENU_TRANSITIOH_TIME = 200;
const MENU_WIDTH = '100%';
const menuTransitionName = 'purrmaster-menu';

const grow = keyframes`
  from {
    width: 0;
  }

  to {
    width: ${MENU_WIDTH};
  }
`;

const PopupWrapper = styled.div`
  top: 0;
  position: absolute;
  overflow: scroll;

  width: ${MENU_WIDTH};
  height: 100vh;

  padding: 20px 10px 8px 10px;

  animation: ${grow} .1s ease 1;
  z-index: ${MENU_ZINDEX};
  background-color: white;

  &.${menuTransitionName}-enter {
    transform: translateX(-100%);
  }
  &.${menuTransitionName}-enter-active {
    transform: translateX(0%);
    transition: transform ${MENU_TRANSITIOH_TIME}ms ease;
  }

  &.${menuTransitionName}-exit {
    transform: translateX(0%);
  }

  &.${menuTransitionName}-exit-active {
    transform: translateX(-100%);
    transition: transform ${MENU_TRANSITIOH_TIME}ms ease;
  }
`

const LinkWrapper = styled.a`
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  color: ${props => props.selected ? NAVIGATION_HIGHLIGHT_COLOR : '#000'};
  &:hover {
    color: ${NAVIGATION_HIGHLIGHT_COLOR};
    text-decoration: underline;
  }
`

const NavigationWrapper = styled.nav`
`

const UlWrapper = styled.ul`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  list-style: none;

  li {
    padding-top: 20px;
  }

  @media ${MEDIA_QUERY.mobileL} { 

  }
`

const SeparatorWrapper = styled.div`
  border-bottom: 1px solid #979797;
  padding-bottom: 15px;
  width: 100%;
`

const NavItemWrapper = styled.li`
  width: fit-content;
`

const BlogNavItemWrapper = styled.li`
  width: fit-content;

  ${LinkWrapper} {
    display: block;
    margin-top: 10px;
  }
`

const HeaderRowWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #979797;

  padding-bottom: 15px;
`

const CrossWraper = styled.span`
  position: absolute;
  left: 10px;
  top: 30px;
`

const TitleLinkWrapper = styled.a`
  cursor: pointer;
  text-align: center;
`

const MyListTextWrapper = styled.span`
  margin-left: 10px;
`

const MyListWrapper = styled.li`
  padding-left: 20px;
`

const MenuDrawer = ({ i18n, setShowMenu, showMenu }) => {
  const router = useRouter()
  const { pathname, query } = router
  const { category } = query
  const close = () => {
    setShowMenu(false)
  }

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      setShowMenu(false)
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CSSTransition
      unmountOnExit
      classNames={menuTransitionName}
      in={showMenu}
      timeout={MENU_TRANSITIOH_TIME}
    >
      <PopupWrapper>
        <HeaderRowWrapper>
          <CrossWraper  onClick={close}>
            <Cross />
          </CrossWraper>
          <Link passHref href='/'>
            <TitleLinkWrapper>
              <SvgPurrmasterLogo/>
            </TitleLinkWrapper>
          </Link>
        </HeaderRowWrapper>
        <NavigationWrapper>
          <UlWrapper>
            <SeparatorWrapper>
              <NavItemWrapper>
                <ClickLoading
                  Placeholder={() => <SvgThreeDots fill='#b5d0d0' {...BUTTON_LOADING_SMALL} />}
                  mode={LOADING_MODE.TRAILING}
                >
                  <Link
                    passHref
                    as={`/brand/${COMPLETE_WET_CATEGORY}`}
                    href="/brand/[category]"
                  >
                    <LinkWrapper selected={category===COMPLETE_WET_CATEGORY}>{i18n.COMPLETE_WET_FOOD}</LinkWrapper>
                  </Link>
                </ClickLoading>
              </NavItemWrapper>
              <NavItemWrapper>
                <ClickLoading
                  Placeholder={() => <SvgThreeDots fill='#b5d0d0' {...BUTTON_LOADING_SMALL} />}
                  mode={LOADING_MODE.TRAILING}
                >
                  <Link
                    passHref
                    as={`/brand/${COMPLETE_DRY_CATEGORY}`}
                    href="/brand/[category]"
                  >
                    <LinkWrapper selected={category===COMPLETE_DRY_CATEGORY}>{i18n.COMPLETE_DRY_FOOD}</LinkWrapper>
                  </Link>
                </ClickLoading>
              </NavItemWrapper>
              <NavItemWrapper>
                <ClickLoading
                  Placeholder={() => <SvgThreeDots fill='#b5d0d0' {...BUTTON_LOADING_SMALL} />}
                  mode={LOADING_MODE.TRAILING}
                >
                  <Link
                    passHref
                    href={SEARCH_PRODUCT_PATHNAME}
                  >
                    <LinkWrapper selected={pathname===SEARCH_PRODUCT_PATHNAME}>{i18n.FIND_CATFOOD}</LinkWrapper>
                  </Link>
                </ClickLoading>
              </NavItemWrapper>
              <NavItemWrapper>
                <ClickLoading
                  Placeholder={() => <SvgThreeDots fill='#b5d0d0' {...BUTTON_LOADING_SMALL} />}
                  mode={LOADING_MODE.TRAILING}
                >
                  <Link
                    passHref
                    href={CALCULATOR_PATHNAME}
                  >
                    <LinkWrapper selected={pathname===CALCULATOR_PATHNAME}>{i18n.INGREDIENT_CALCULATOR}</LinkWrapper>
                  </Link>
                </ClickLoading>
              </NavItemWrapper>
              <BlogNavItemWrapper>
                <Expandable
                  label={
                  <LinkWrapper href='#'>{i18n['CAT_KNOWLEDGE']}</LinkWrapper>
                }
                >
                  <ClickLoading
                    Placeholder={() => <SvgThreeDots fill='#b5d0d0' {...BUTTON_LOADING_SMALL} />}
                    isInline={true}
                    mode={LOADING_MODE.TRAILING}
                  >
                    <Link
                      passHref
                      href={COMPLETE_AND_SUPPLEMENT_PATHNAME}
                    >
                      <LinkWrapper selected={pathname===COMPLETE_AND_SUPPLEMENT_PATHNAME}>
                        {i18n['COMPLETE_AND_SUPPLEMENT']}
                      </LinkWrapper>
                    </Link>
                  </ClickLoading>
                  <ClickLoading
                    Placeholder={() => <SvgThreeDots fill='#b5d0d0' {...BUTTON_LOADING_SMALL} />}
                    isInline={true}
                    mode={LOADING_MODE.TRAILING}
                  >
                    <Link
                      passHref
                      href={BLOG_NUTRITION_PATHNAME}
                    >
                      <LinkWrapper selected={pathname===BLOG_NUTRITION_PATHNAME}>
                        {i18n['BLOG_NUTRITION_TITLE']}
                      </LinkWrapper>
                    </Link>
                  </ClickLoading>
                  <ClickLoading
                    Placeholder={() => <SvgThreeDots fill='#b5d0d0' {...BUTTON_LOADING_SMALL} />}
                    isInline={true}
                    mode={LOADING_MODE.TRAILING}
                  >
                    <Link
                      passHref
                      href={BLOG_PLANT_PATHNAME}
                    >
                      <LinkWrapper selected={pathname===BLOG_PLANT_PATHNAME}>
                        {i18n['BLOG_PLANT_TITLE']}
                      </LinkWrapper>
                    </Link>
                  </ClickLoading>
                  <ClickLoading
                    Placeholder={() => <SvgThreeDots fill='#b5d0d0' {...BUTTON_LOADING_SMALL} />}
                    isInline={true}
                    mode={LOADING_MODE.TRAILING}
                  >
                    <Link
                      passHref
                      href={BLOG_CONTROVERSIAL_GUM_PATHNAME}
                    >
                      <LinkWrapper selected={pathname===BLOG_CONTROVERSIAL_GUM_PATHNAME}>
                        {i18n['BLOG_CONTROVERSIAL_GUM_TITLE']}
                      </LinkWrapper>
                    </Link>
                  </ClickLoading>
                  <ClickLoading
                    Placeholder={() => <SvgThreeDots fill='#b5d0d0' {...BUTTON_LOADING_SMALL} />}
                    isInline={true}
                    mode={LOADING_MODE.TRAILING}
                  >
                    <Link
                      passHref
                      href={TOP_10_PRODUCTS}
                    >
                      <LinkWrapper selected={pathname===TOP_10_PRODUCTS}>
                        {i18n['TOP_10_PRODUCTS']}
                      </LinkWrapper>
                    </Link>
                  </ClickLoading>
                  <ClickLoading
                    Placeholder={() => <SvgThreeDots fill='#b5d0d0' {...BUTTON_LOADING_SMALL} />}
                    isInline={true}
                    mode={LOADING_MODE.TRAILING}
                  >
                    <Link
                      passHref
                      href={FOOD_ANAYLYSIS}
                    >
                      <LinkWrapper selected={pathname===FOOD_ANAYLYSIS}>
                        {i18n['FOOD_ANAYLYSIS']}
                      </LinkWrapper>
                    </Link>
                  </ClickLoading>
                  <ClickLoading
                    Placeholder={() => <SvgThreeDots fill='#b5d0d0' {...BUTTON_LOADING_SMALL} />}
                    isInline={true}
                    mode={LOADING_MODE.TRAILING}
                  >
                    <Link
                      passHref
                      href={CAT_INSURANCE}
                    >
                      <LinkWrapper selected={pathname===CAT_INSURANCE}>
                        {i18n['CAT_INSURANCE']}
                      </LinkWrapper>
                    </Link>
                  </ClickLoading>
                </Expandable>
              </BlogNavItemWrapper>
            </SeparatorWrapper>
            <SeparatorWrapper>
              <NavItemWrapper>
                <ClickLoading
                  Placeholder={() => <SvgThreeDots fill='#b5d0d0' {...BUTTON_LOADING_SMALL} />}
                  mode={LOADING_MODE.TRAILING}
                >
                  <NavUserBadge i18n={i18n} pathname={pathname} text={i18n['LOGIN']} />
                </ClickLoading>
              </NavItemWrapper>
              <MyListWrapper>
                <ClickLoading
                  Placeholder={() => <SvgThreeDots fill='#b5d0d0' {...BUTTON_LOADING_SMALL} />}
                  mode={LOADING_MODE.TRAILING}
                >
                  <Link
                    passHref
                    href={USER_FAV_PATHNAME}
                  >
                    <LinkWrapper selected={pathname===USER_FAV_PATHNAME}>
                      <SvgFavorite />
                      <MyListTextWrapper>
                        {i18n['MY_LIST']}
                      </MyListTextWrapper>
                    </LinkWrapper>
                  </Link>
                </ClickLoading>
              </MyListWrapper>
            </SeparatorWrapper>
          </UlWrapper>
        </NavigationWrapper>
      </PopupWrapper>
    </CSSTransition>
  )
}

export default MenuDrawer
