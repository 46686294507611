/* eslint-disable sort-keys */
// Common
export const HIGHLIGHT_COLOR = '#35d0cc';
export const HIGHLIGHT_BG_COLOR = '#b5d0d0';
export const NEGATIVE_HIGHLIGHT_COLOR = '#e02020';
export const STAR_SCORE_COLOR = '#ffbb00';
export const BACKGROUND_HUE_COLOR = '#fafafa';
export const ACTION_COLOR = '#b5d0d0';

// button
export const BUTTON_DISABLED_COLOR = '#aaa';
export const BUTTON_REDIRECT_COLOR = '#b5d0d0';
export const BUTTON_LOADING_BIG = {
  circleY: 11,
  height: 18,
  r: 7,
  width: 86,
  xOffset: 30,
}
export const BUTTON_LOADING_SMALL = {
  circleY: 11,
  height: 16,
  r: 5,
  width: 55,
  xOffset: 15,
};
export const BUTTON_LOADING_TINY = {
  circleY: 5,
  height: 8,
  r: 3,
  width: 22,
  x: 3,
  xOffset: 7,
};

// submit button color
export const SB_BG_COLOR ='#fafafa';
export const SB_COLOR ='#333';

// Button style: Signin Signup
export const BUTTON_STYLE_MAIN = {
  bgColor: SB_BG_COLOR,
  bgImage: "linear-gradient(277deg, #63c2c6 40%, #64ebca)",
  border: "none",
  borderRadius: '21px',
  color: '#fff',
  lineHeight: 1,
  width: '100%'
}

// review button color
export const RV_BG_COLOR = '#b5d0d0';

// cancel button color
export const CANCEL_BG_COLOR = '#e7e7e7';
export const CANCEL_COLOR = '#6a6a6a';

// publish button color
export const PUBLISH_BG_COLOR = '#64ebca';
export const PUBLISH_COLOR = '#ffffff';

// sort button style
export const BUTTON_SORT = {
  border: 'none',
  color: '#000',
  bgColor: BACKGROUND_HUE_COLOR,
}

// Carousel
export const TOUCH_THRESDHOLD = 10;

// HEADER
export const HEADER_BG_COLOR = '#fafafa';
export const HEADER_HEIGHT = 100;
export const HEADER_ZINDEX = 200;

// FOOTER
export const FOOTER_BG_COLOR = '#fafafa'

// Landing Layout
export const LANDING_WIDTH = '92%';
export const MOBILE_LANDING_WIDTH = 332;

// List
export const DOT_COLOR = '#b5d0d0'
export const DOT_PRO_COLOR = '#64ebca'
export const DOT_WARNING_COLOR = '#ffbb00'
export const DOT_CON_COLOR = '#e02020'

// Navigation
export const NAVIGATION_HIGHLIGHT_COLOR = '#aebdac'

// Media query
export const DEVICE_SIZE = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}

export const MEDIA_QUERY = {
  mobileS: `(min-width: ${DEVICE_SIZE.mobileS})`,
  mobileM: `(min-width: ${DEVICE_SIZE.mobileM})`,
  // When width > DEVICE_SIZE.mobileL, this rule will be adopted
  mobileL: `(min-width: ${DEVICE_SIZE.mobileL})`,
  tablet: `(min-width: ${DEVICE_SIZE.tablet})`,
  laptop: `(min-width: ${DEVICE_SIZE.laptop})`,
  laptopL: `(min-width: ${DEVICE_SIZE.laptopL})`,
  desktop: `(min-width: ${DEVICE_SIZE.desktop})`,
  desktopL: `(min-width: ${DEVICE_SIZE.desktop})`
}

// fav icons
export const FAV_ICONS_LIKED_COLOR = '#ff008a'
export const FAV_ICONS_EMPTY_COLOR = '#fff'

// modal
export const MENU_ZINDEX = 500;
export const SHADOW_ZINDEX = 1400;
export const SHADOW_COLOR = 'rgba(0, 0, 0, 0.5)';
export const MODAL_TRANSITIOH_TIME = 20;
export const MODAL_WIDTH = 481;
export const MODAL_HEIGHT = 528;
export const MODAL_MOBILE_HEIGHT = 400;
export const MODAL_BG_COLOR = '#ffffff';

// product
export const ALLERGIC_COLOR = '#5B1313';
export const QUESTIONABLE_COLOR = '#d51400';
export const PRODUCT_STARS_BASED_COLOR = '#c9c9c9';
export const PRODUCT_STARS_WIDTH = '88px';
export const ADS_WIDTH = '100px';
export const SHAPE_BG_COLOR = '#cecece';

// brand search
export const SEARCH_SUGGESTION_ZINDEX = 10;
export const PRODUCT_IMG_HEIGHT = 172;
export const PRODUCT_IMG_WIDTH = 196.3;

// Login
export const FB_BLUE_COLOR = '#172a88'
export const GOOGLE_RED_COLOR = '#c30d23'

// review form
export const REVICE_FORM_STAR_BASEDCOLOR = '#ffffff';
export const REVIEW_FORM_START_STROKECOLOR = '#868686';
export const REVIEW_FORM_STARS_WIDTH = '110PX';
export const REVIEW_FORM_HEIGHT = 'auto';

//scroll to top
export const SCROLL_TO_TOP_COLOR = '#587797'

//products search
export const SORT_SUGGESTION_ZINDEX = 10;
export const SORT_SUGGESTION_WIDTH = '130px';
export const SCROLL_TO_TOP_SUGGESTION_ZINDEX = 9;
