import Link from "next/link"
import { useRouter } from "next/router"
import styled from "styled-components"
import { ABOUT_PATHNAME, FB_PAGE, PRIVACY_PATHNAME } from "../../constants/route"
import { FOOTER_BG_COLOR, MEDIA_QUERY, NAVIGATION_HIGHLIGHT_COLOR } from "../../constants/style"
import { THIS_YEAR } from "../../helpers/someTime"

const FooterWrapper = styled.footer`
  font-size: 15px;
  background-color: ${FOOTER_BG_COLOR};
  color: #c1c1c1;
  margin: 20px auto 0 auto;
  padding: 20px;
  display: block;

  @media ${MEDIA_QUERY.mobileL} { 
    /* display: none; */
  }
`

const LinkWrapper = styled.a`
  cursor: pointer;
  color: #c1c1c1;
  text-decoration: ${props => props.selected ? 'underline' : 'none'};
  &:hover {
    text-decoration: underline;
  }
`

const FootItemWrapper = styled.span`
  margin-left: 10px;
`

export default function Footer({ i18n }) {
  const router = useRouter()
  const { pathname } = router
  return (
    <FooterWrapper>
      <FootItemWrapper>
        <span>{i18n['FOOTER_NOTE']}</span>
      </FootItemWrapper>
      <FootItemWrapper>{`${i18n['PROJECT_NAME']} © ${THIS_YEAR}`}</FootItemWrapper>
      <FootItemWrapper>
        <Link
            passHref
            href={ABOUT_PATHNAME}
          >
            <LinkWrapper selected={pathname===ABOUT_PATHNAME}>{i18n['ABOUT']}</LinkWrapper>
        </Link>
      </FootItemWrapper>
      <FootItemWrapper>
        <Link
            passHref
            href='mailto:service@purrmaster.com'
          >
            <LinkWrapper >{i18n['CONTACT']}</LinkWrapper>
        </Link>
      </FootItemWrapper>
      <FootItemWrapper>
        <Link
            passHref
            href={`${PRIVACY_PATHNAME}/zh-TW`}
          >
            <LinkWrapper selected={pathname === PRIVACY_PATHNAME}>{i18n['PRIVACY']}</LinkWrapper>
        </Link>
      </FootItemWrapper>
      <FootItemWrapper>
        <Link
            passHref
            href={`${FB_PAGE}`}
          >
            <LinkWrapper selected={pathname === FB_PAGE}>{i18n['FB_PAGE']}</LinkWrapper>
        </Link>
      </FootItemWrapper>
    </FooterWrapper>
  )
}
