import { useState, useEffect } from 'react'
import SvgUser from "../icons/User"
import { user } from "../../helpers/clientStorage"
import styled from "styled-components"
import { MEDIA_QUERY, NAVIGATION_HIGHLIGHT_COLOR } from "../../constants/style"
import { LOGIN_PATHNAME, USER_PATHNAME } from "../../constants/route"
import { useRouter } from 'next/router'
import getUserPicture from '../../helpers/getUserPicture'

const AvatarWrapper = styled.span`
  width: 25px;
  height: 25px;
  border-radius: 50%;
`
const Picture = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50%;
`

const IconWrapper = styled.div`

`

const TextWrapper = styled.span`
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 5px;
  @media ${MEDIA_QUERY.mobileL} { 
    max-width: 120px;
  }
`

const LinkWrapper = styled.a`
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  color: ${props => props.selected ? NAVIGATION_HIGHLIGHT_COLOR : '#000'};
  &:hover {
    color: ${NAVIGATION_HIGHLIGHT_COLOR};
    text-decoration: underline;
  }
`
const LoginWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`

function onClickBadge({ event, isLogin, router }) {
  event.preventDefault()
  const path = isLogin ? '/user' : '/login'
  const { pathname, search } = window.location

  if (path === pathname) return;
  const from = `?from=${pathname}${search}`
  const destination = isLogin ? path : `${path}${from}`
  router.push(destination)
}

export default function NavBadge({ i18n, pathname }) {
  const [ currentUser, setUser ] = useState({})
  useEffect(() => {
    const data = user.get()
    setUser(data)
  }, [])
  const router = useRouter()
  const { displayName, email, picture } = currentUser || {}
  const isLogin = !!email
  const prioritizedText = displayName || email;
  const authPicture = getUserPicture(picture)
  const text = isLogin ? i18n['USER_PROFILE'] : i18n['LOGIN']
  const avatar = picture ?
    <AvatarWrapper><Picture src={authPicture} /></AvatarWrapper> :
    <IconWrapper><SvgUser/></IconWrapper>
  
  return (
      <LinkWrapper
        isLogin={isLogin}
        onClick={(event) => onClickBadge({ event, isLogin, router })}
        selected={pathname===LOGIN_PATHNAME || pathname===USER_PATHNAME}
      >
        <LoginWrapper>
          {
            avatar
          }
          <TextWrapper>{prioritizedText || text}</TextWrapper>
        </LoginWrapper>
      </LinkWrapper>
  )
}
