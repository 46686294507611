import Link from "next/link"
import styled from "styled-components"

const InternalAnchor = styled.a`
`

const CoverLink = ({ isInternal, cover, children }) => {
  if (isInternal) {
    return (
      <Link passHref href={cover.path}>
        <InternalAnchor>
          {children}
        </InternalAnchor>
      </Link>
    )
  }

  return (
    <a
      href={cover.link}
      rel="noreferrer"
      target='_blank'
    >
      {children}
    </a>
  )   
}

export default CoverLink
