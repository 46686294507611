import styled from "styled-components"
import { ADS_WIDTH, HEADER_ZINDEX, HEADER_BG_COLOR, MEDIA_QUERY } from "../../constants/style"

export const HeaderRootWrapper = styled.header`
  background-color: ${HEADER_BG_COLOR};
  display: flex;
  width: inherit;
  position: fixed;
  top: 0;
  flex-direction: column; 
  z-index: ${HEADER_ZINDEX};
`
export const HeaderWrapper = styled.div`
  box-sizing: border-box;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 20px 10px 8px 10px;
  flex-direction: column;
  z-index: ${(props) => props.zIndex ? props.zIndex : 0};
  @media ${MEDIA_QUERY.mobileL} {
    padding: 30px 10px 8px 10px;
    margin: 0 ${ADS_WIDTH};
  }
`