import { divide } from "./calculate";

const THIS_YEAR = new Date().getFullYear()

function getTimestamp(){
  /**
   * IE8
   */
  if (!Date.now) {
    return divide(new Date().getTime(), 1e3, 0).valueOf()
  }
  return divide(Date.now(), 1e3, 0).valueOf()
}


export {
  THIS_YEAR,
  getTimestamp
}
