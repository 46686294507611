import styled from "styled-components"
import { COVER_TYPE, DEFAULT_LOCALE } from "../../../constants/misc"
import { MEDIA_QUERY } from "../../../constants/style"
import NewProduct from "./templates/NewProduct"
import { useRouter } from 'next/router'
import typecast from "../../../helpers/typecast"
import Announcement from "./templates/Announcement"

const CoverWrapper = styled.img`
  position: relative;
  object-position: center center;
  object-fit: cover;
  overflow: hidden;
  outline: none;
  width: 100%;

  border-radius: 0px;
  height: 205px;

  @media ${MEDIA_QUERY.mobileL} { 
    height: 346px;
    border-radius: 8px;
  }
`

const ContentWrapper = styled.div`
  position: relative;
  &:after {
    background: ${(props) => props.isShadow ? 'linear-gradient(-180deg,rgba(18,23,23,0) 28%,rgba(18,23,23,.04) 34%,rgba(18,23,23,.84) 100%)' : 'transparent'};
    bottom: 0;
    content: ' ';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 20;
  }
`

const CoverContent = ({ cover }) => {
  const { template = {}, debug } = cover;
  const localeConfig = template[DEFAULT_LOCALE];
  const router = useRouter()
  function getTemplateComponent(config) {
    if (!config) { return null }
    if (debug) {
      const { debugMode } = router.query;
      if (!typecast(debugMode, 'boolean')) { return }
    }
    switch (config.type) {
      case COVER_TYPE.NEW_PRODUCT:
        return (
          <NewProduct
            config={config}
          />
        );
      case COVER_TYPE.ANNOUNCEMENT: {
        return (
          <Announcement config={config} />
        )
      }
      default:
        return null
    }
  }

  return (
    <ContentWrapper
      isShadow={!!localeConfig}
    >
      <CoverWrapper
        alt={cover.alt}
        loading='lazy'
        src={cover.src}
      />
        {
          getTemplateComponent(localeConfig)
        }
    </ContentWrapper>
  )
}

export default CoverContent;
