import Head from 'next/head' 
import styled from 'styled-components';
import { HEADER_HEIGHT, MEDIA_QUERY } from '../../constants/style';
import Header from '../header/Header';
import { LandingWrepper } from '../style/LandingWrapper';
import DesktopCover from '../cover/DesktopCover';
import Footer from '../footer/Footer';
import ConfigV1 from '../../live-config/config-v1.json';

const ContentWrapper = styled.main`
  margin-top: ${HEADER_HEIGHT - 30}px;
  @media ${MEDIA_QUERY.mobileL} {
    margin-top: ${HEADER_HEIGHT}px;
    padding: 0 6px;
  }
`

/**
 * Landing
 * RWD layout
 */
export default function Landing(props) {
  const { children, i18n, liveConfig = ConfigV1, title } = props;
  const metaTitle = title ? title : ''
  return (
    <LandingWrepper>
      <Head>
        <title>{`${metaTitle} - ${i18n['META_TITLE']}`}</title>
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" name="viewport" />
      </Head>
      
      <Header i18n={i18n} liveConfig={liveConfig} />
      <ContentWrapper>
        <DesktopCover
          liveConfig={liveConfig}
        />
        {children}
      </ContentWrapper>
      <Footer i18n={i18n} />
    </LandingWrepper>
  );
}
