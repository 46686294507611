import { MEDIA_QUERY } from "../../../constants/style";

const { default: styled } = require("styled-components");

const NextButtonWrapper = styled.div`
  display: none;
  &:hover {
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
  }
  &:before {
    border-left: 8px solid #fff;
    content: '';
    margin: 0 5px;
    display: inline-block;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
  }
  &:focus,
  &:active {
    outline: none;
  }
  @media ${MEDIA_QUERY.mobileL} {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    top: 0;
    color: #fff;
    font-size: 26px;
    bottom: 0;
    border-radius: 22px;
    margin-top: 0;
    padding: 5px;
    position: absolute;
    right: 0;
    z-index: 10;
    background-color: transparent;
    opacity: 0.4;
    transition: all 0.25s ease-in;
  }
`

export default NextButtonWrapper
