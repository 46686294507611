import styled from "styled-components";
import { ADS_WIDTH, MEDIA_QUERY } from "../../../../constants/style";

const TitleWrapper = styled.h3`
  @media ${MEDIA_QUERY.mobileL} { 
    display: block;
  }
`

const ProductWrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100px;
  width: 400px;
  overflow: hidden;
  @media ${MEDIA_QUERY.mobileL} { 
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 200px;
  }
`

const ProductItemWrapper = styled.li`
  margin-bottom: 5px;
`

const RootWrapper = styled.div`
  position: absolute;
  bottom: 21px;
  color: ${(props) => props.color ? props.color :`rgba(255,255,255, 0.6)`};
  width: 100%;
  z-index: 30;
  margin: 0 25px;
  font-weight: 500;
  @media ${MEDIA_QUERY.mobileL} { 
    margin: 0 ${ADS_WIDTH};
  }
`

const NewProduct = ({ config }) => {
  const { title  = '', products = [], color } = config;
  return (
    <RootWrapper color={color}>
      <TitleWrapper>
        {title}
      </TitleWrapper>
      <ProductWrapper>
        {
          products.map((product, index) => {
            return (
              <ProductItemWrapper
                key={index}
              >
                {product}
              </ProductItemWrapper>
            )
          })
        }
      </ProductWrapper>
    </RootWrapper>
  )
}

export default NewProduct;
