import styled from "styled-components";
import { MEDIA_QUERY } from "../../constants/style";
import { CoverCarousel } from "./CoverCarousel";
const Wrapper = styled.section`
  overflow: hidden;
  margin: auto;
  width: 100%;
  @media ${MEDIA_QUERY.laptop} { 
    height: auto;
  }
`

export default function DesktopCover(props) {
  return (
    <Wrapper>
      <CoverCarousel {...props}/>
    </Wrapper>
  );
}
