import styled from "styled-components";
import { ADS_WIDTH, MEDIA_QUERY } from "@constants/style";

const TitleWrapper = styled.h3`
@media ${MEDIA_QUERY.mobileL} { 
  display: block;
}
`

const RootWrapper = styled.div`
  position: absolute;
  bottom: 10%;
  color: ${(props) => props.color ? props.color : `#fff`};
  width: 100%;
  z-index: 30;
  margin-left: 5px;
  font-weight: 500;
  @media ${MEDIA_QUERY.mobileL} { 
    margin: 0 ${ADS_WIDTH};
  }
`

const Announcement = ({ config }) => {
  const { title  = '', color } = config;
  return (
    <RootWrapper color={color}>
      <TitleWrapper>
        {title}
      </TitleWrapper>
    </RootWrapper>
  )
}

export default Announcement
