import React from "react";
import { FAV_ICONS_LIKED_COLOR } from "../../constants/style";

function SvgFavorite(props) {
  const { fillColor, ...rest } = props
  return (
    <svg height={13} width={15} {...rest}>
      <defs>
        <filter
          filterUnits="objectBoundingBox"
          height="108.7%"
          id="favorite_svg__a"
          width="107.2%"
          x="-1.8%"
          y="-2.2%"
        >
          <feOffset
            dx={0.5}
            dy={0.5}
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            in="shadowOffsetOuter1"
            values="0 0 0 0 0.48607337 0 0 0 0 0.48607337 0 0 0 0 0.48607337 0 0 0 1 0"
          />
        </filter>
        <path
          d="M1202.99 78.9c-1.278 0-2.323.58-3.02 1.394-.697-.93-1.743-1.394-3.02-1.394a3.917 3.917 0 00-3.95 3.95c0 .696.232 1.393.58 1.974.233.349.582.697.814 1.046l5.576 4.53 5.575-4.53c.233-.349.581-.697.814-1.046.348-.58.58-1.278.58-1.975a3.917 3.917 0 00-3.95-3.949z"
          id="favorite_svg__b"
        />
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        transform="translate(-1193 -78)"
      >
        <use
          fill="#000"
          filter="url(#favorite_svg__a)"
          xlinkHref="#favorite_svg__b"
        />
        <use fill={fillColor || FAV_ICONS_LIKED_COLOR} xlinkHref="#favorite_svg__b" />
      </g>
    </svg>
  );
}

export default SvgFavorite;
