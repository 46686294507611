import styled from "styled-components"
import Link from 'next/link'
import { MEDIA_QUERY, NAVIGATION_HIGHLIGHT_COLOR } from "../../constants/style"
import { BLOG_PATHNAME, BLOG_NUTRITION_PATHNAME, CALCULATOR_PATHNAME, COMPLETE_DRY_CATEGORY, COMPLETE_WET_CATEGORY, SEARCH_PRODUCT_PATHNAME, USER_FAV_PATHNAME, BLOG_PLANT_PATHNAME, BLOG_CONTROVERSIAL_GUM_PATHNAME, COMPLETE_AND_SUPPLEMENT_PATHNAME, TOP_10_PRODUCTS, FOOD_ANAYLYSIS, CAT_INSURANCE } from "../../constants/route"
import { useRouter } from "next/router"
import NavUserBadge from "../user/NavBadge"
import SvgFavorite from "../icons/Favorite"
import { DEFAULT_LOCALE } from "@constants/misc"

const NavWrapper = styled.nav`
  display: none;
  @media ${MEDIA_QUERY.tablet} {
    height: 20px;
    display: block;
  }
`
const LinkWrapper = styled.a`
  position: relative;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  color: ${props => props.selected ? NAVIGATION_HIGHLIGHT_COLOR : '#000'};
  &:hover {
    color: ${NAVIGATION_HIGHLIGHT_COLOR};
    text-decoration: underline;
  }
`

const UlWrapper = styled.ul`
  align-items: center;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;

  li {
    margin-right: 11px;
  }

  @media ${MEDIA_QUERY.mobileL} { 
    justify-content: initial;
    justify-content: flex-end;
  }
`

const KnowledgeWrapper = styled.div`
  display: none;
  position: absolute;
  top: 25px;
  left: -15px;
  border-radius: 6px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  box-sizing: border-box;
  cursor: pointer;
  padding: 10px 9px;
  width: 180px;

  div:nth-child(1) {
    margin-bottom: 10px;
  }

  ${LinkWrapper} {
    display: block;
    margin-top: 10px;
  }
`

const KnowledgetHoverWrapper = styled.span`
  padding-bottom: 10px;
  &:hover {
    ${KnowledgeWrapper} {
      display: block;
    }
  }
`

function getLinkComp({selected, template = {}, href}){
  const localeTemplate = template[DEFAULT_LOCALE];

  return (
    <Link
      passHref
      href={href}
    >
      <LinkWrapper selected={selected}>
        {localeTemplate.title}
      </LinkWrapper>
    </Link>
  )
}

export default function Navigation(props) {
  const { i18n, liveConfig } = props
  const router = useRouter()
  const { pathname, query } = router
  const { category } = query
  const blog = liveConfig.blog || [];

  return (
    <NavWrapper>
      <UlWrapper>
        <li>
          <Link
            passHref
            as={`/brand/${COMPLETE_WET_CATEGORY}`}
            href="/brand/[category]"
          >
            <LinkWrapper selected={category===COMPLETE_WET_CATEGORY}>{i18n.COMPLETE_WET_FOOD}</LinkWrapper>
          </Link>
        </li>
        <li>
          <Link
            passHref
            as={`/brand/${COMPLETE_DRY_CATEGORY}`}
            href="/brand/[category]"
          >
            <LinkWrapper selected={category===COMPLETE_DRY_CATEGORY}>{i18n.COMPLETE_DRY_FOOD}</LinkWrapper>
          </Link>
        </li>
        <li>
          <Link
            passHref
            href={SEARCH_PRODUCT_PATHNAME}
          >
            <LinkWrapper selected={pathname===SEARCH_PRODUCT_PATHNAME}>{i18n.FIND_CATFOOD}</LinkWrapper>
          </Link>
        </li>
        <li>
          <Link
            passHref
            href={CALCULATOR_PATHNAME}
          >
            <LinkWrapper selected={pathname===CALCULATOR_PATHNAME}>{i18n.INGREDIENT_CALCULATOR}</LinkWrapper>
          </Link>
        </li>
        <li>
          <LinkWrapper
            href={BLOG_NUTRITION_PATHNAME}
            selected={typeof pathname === 'string' && pathname.includes(BLOG_PATHNAME)}
          >
            <KnowledgetHoverWrapper>
              {i18n['CAT_KNOWLEDGE']}
              <KnowledgeWrapper>
                  <Link
                    passHref
                    href={COMPLETE_AND_SUPPLEMENT_PATHNAME}
                  >
                    <LinkWrapper selected={pathname===COMPLETE_AND_SUPPLEMENT_PATHNAME}>
                      {i18n['COMPLETE_AND_SUPPLEMENT']}
                    </LinkWrapper>
                  </Link>
                  <Link
                    passHref
                    href={BLOG_NUTRITION_PATHNAME}
                  >
                    <LinkWrapper selected={pathname===BLOG_NUTRITION_PATHNAME}>
                      {i18n['BLOG_NUTRITION_TITLE']}
                    </LinkWrapper>
                  </Link>
                  <Link
                    passHref
                    href={BLOG_PLANT_PATHNAME}
                  >
                    <LinkWrapper selected={pathname===BLOG_PLANT_PATHNAME}>
                      {i18n['BLOG_PLANT_TITLE']}
                    </LinkWrapper>
                  </Link>
                  <Link
                    passHref
                    href={BLOG_CONTROVERSIAL_GUM_PATHNAME}
                  >
                    <LinkWrapper selected={pathname===BLOG_CONTROVERSIAL_GUM_PATHNAME}>
                      {i18n['BLOG_CONTROVERSIAL_GUM_TITLE']}
                    </LinkWrapper>
                  </Link>
                  <Link
                    passHref
                    href={TOP_10_PRODUCTS}
                  >
                    <LinkWrapper selected={pathname===TOP_10_PRODUCTS}>
                      {i18n['TOP_10_PRODUCTS']}
                    </LinkWrapper>
                  </Link>
                  <Link
                    passHref
                    href={FOOD_ANAYLYSIS}
                  >
                    <LinkWrapper selected={pathname===FOOD_ANAYLYSIS}>
                      {i18n['FOOD_ANAYLYSIS']}
                    </LinkWrapper>
                  </Link>
                  <Link
                    passHref
                    href={CAT_INSURANCE}
                  >
                    <LinkWrapper selected={pathname===CAT_INSURANCE}>
                      {i18n['CAT_INSURANCE']}
                    </LinkWrapper>
                  </Link>
                </KnowledgeWrapper>
            </KnowledgetHoverWrapper>
          </LinkWrapper>
        </li>
        <li>
          <NavUserBadge i18n={i18n} pathname={pathname} />
        </li>
        <li>
          <Link
            passHref
            href={USER_FAV_PATHNAME}
          >
            <LinkWrapper selected={pathname===USER_FAV_PATHNAME}><SvgFavorite /></LinkWrapper>
          </Link>
        </li>
      </UlWrapper>
    </NavWrapper>
  );
};
